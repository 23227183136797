import React, { Component } from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { gsap } from 'gsap';
import Select from 'react-select';
import validator from 'validator/es';
import Cookies from 'js-cookie';
import fetch from 'isomorphic-unfetch';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import { media } from '../styles/media';
import bgContact from '../assets/images/bg--contact--success.png';
import video from '../assets/video/video.mp4';
import videoExplainer from '../assets/video/CloudKitchens_Explainer.mp4';

const Section = styled.section`
  position: relative;
  overflow: hidden;
  .referral_name {
    display: none;
    &.active {
      display: block;
    }
  }
  .bg--hero--holder {
    position: relative;
    z-index: 1;
    width: 100%;
    height: 225px;
    overflow: hidden;
    background: var(--primary);
    &.primary-dark {
      background: var(--primary-dark);
    }
    @media ${media.mdUp} {
      height: 420px;
      margin: -300px 0 0;
      padding-left: 0;
    }
    @media ${media.lgUp} {
      margin: -300px 0 0;
      height: 660px;
    }
  }
  .bg--hero {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    background: var(--grey-2) no-repeat;
    background-position: bottom;
    background-size: cover;
    overflow: hidden;
    @media ${media.mdUp} {
      background-position: center center;
    }
  }
  .bg--right {
    position: relative;
    z-index: 5;
    &::before {
      display: none;
      position: absolute;
      z-index: 1;
      content: '';
      bottom: 0;
      right: -100%;
      height: 100%;
      width: 100%;
      background: var(--grey-1);

      @media ${media.mdUp} {
        display: block;
        left: -100%;
        right: auto;
      }
      @media ${media.lgUp} {
        display: none;
      }
    }
    &.bg--primary-dark::before {
      background: var(--primary-dark);
    }
  }
  .video--holder {
    height: 0;
    padding-bottom: 56%;
    background: linear-gradient(
      180deg,
      var(--grey-2) 0,
      var(--grey-2) 50%,
      var(--primary) 50%,
      var(--primary) 100%
    );
    video {
      margin-left: 32px;
      object-fit: cover;
    }
    @media ${media.mdUp} {
      padding-bottom: 67%;
    }
    @media ${media.lgUp} {
      height: calc(100vh - 68px);
      min-height: 620px;
      padding-bottom: 0;
      background: none;
      video {
        margin-left: 0;
      }
    }
  }
  .modal {
    background: var(--black);
    .video--holder {
      video {
        bottom: 0;
        margin: auto;
        object-fit: contain;
        height: auto;
        @media ${media.lgUp} {
          object-fit: cover;
          height: 100%;
        }
      }
    }
  }
  .form--holder {
    @media (max-width: 720px) {
      margin: 0 -36px;
      padding: 32px 36px 60px;
    }
    @media (max-width: 374px) {
      margin: 0 -16px;
      padding: 32px 16px 60px;
    }
  }
  .bg--contact--success {
    background: url(${bgContact}) center bottom no-repeat;
    background-size: contain;
  }
  .bg--left {
    &::before {
      position: absolute;
      z-index: -1;
      content: '';
      bottom: 0;
      left: -50%;
      height: 100%;
      width: 100%;
      background: var(--grey-1);
      @media ${media.xlUp} {
        left: -98%;
      }
    }
    hr {
      border-top: 1px solid var(--grey-3);
    }
  }
  .form--element--placeholder,
  .form--element--text,
  .form--element--select {
    font-size: 18px !important;
    @media ${media.lgUp} {
      font-size: 24px !important;
    }
  }
`;

class BlockRetailThankYou extends Component {
  componentDidMount() {
    const fadeElements = gsap.timeline();
    fadeElements.to('#contactTop', {
      opacity: 1,
      delay: 0.25,
    });
    fadeElements.from('#contactTop .fade-in-1', {
      duration: 0.6,
      opacity: '0',
      y: '5%',
    });
    fadeElements.from(
      '#contactTop .bg--hero--holder',
      {
        duration: 0.6,
        opacity: '0',
      },
      '-=0.4'
    );
    fadeElements.from(
      '#contactTop .fade-in-2',
      {
        duration: 0.7,
        opacity: '0',
        y: '20%',
        stagger: {
          amount: '0.25',
        },
      },
      '-=0.6'
    );
    fadeElements.from(
      '#contactTop .fade-up',
      {
        duration: 0.7,
        opacity: '0',
        y: '20%',
        stagger: {
          amount: '0.5',
        },
      },
      '-=1'
    );

    const bg = document.querySelector('.bg--hero');

    const bgTL = gsap.timeline({
      scrollTrigger: {
        trigger: '#contactTop',
        scrub: 1,
        start: 'top 90%',
      },
    });

    bgTL.fromTo(bg, { scale: 1.1 }, { scale: 1, force3D: true }, 0);
  }

  render() {
    const backgroundImage = { backgroundImage: `url(${this.props.hero})` };

    setTimeout(function () {
      console.log('test');
      window.location.href = 'https://retail.cloudkitchens.com/';
    }, 5000);

    return (
      <Section className="relative bg--primary bg--retail md:pt-10 lg:pt-0">
        <div className="container">
          <div className="grid sm:grid-cols-4 md:grid-cols-8 lg:grid-cols-12">
            <div
              className={`col-span-8 md:col-span-4 md:col-start-1 lg:col-span-6 bg--${this.props.color} mt-12 md:mt-0 lg:mt-12 form--holder fade-in-1 bg--right`}
            >
              <div className="lg:pl-10 md:pt-10 md:pb-10 lg:p-16">
                <h1 className="h2 color--grey-6 md:pr-10 lg:pr-0 mb-10 md:mb-12 fade-up">
                  {this.props.title}
                </h1>
                <p className="mb-10 md:mb-12 lg:mb-12 w-450 fade-up color--grey-5 md:pr-10 lg:pr-0">
                  We appreciate your interest in CloudRetail.
                  <br className="hidden lg:block" />
                  One of our colleagues will get in touch with you soon.
                </p>
                <Link
                  to="/"
                  className="btn--rounded btn--bg btn--bg--secondary btn--border--secondary color--grey-1"
                >
                  Return home
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className={`${this.props.color} bg--hero--holder`}>
          <div className="bg--hero" style={backgroundImage} />
        </div>
      </Section>
    );
  }
}
export default BlockRetailThankYou;

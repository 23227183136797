import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import BlockSixUp from '../components/BlockSixUp';
import BlockTestimonialsSlider from '../components/BlockTestimonialsSlider';
import Meta from '../components/Meta';
import BlockCTA from '../components/BlockCTA';
import BlockOverview from '../components/BlockOverview';
import bgHero from '../assets/images/hero--retail.png';
import bgContact from '../assets/images/bg--contact--retail.jpg';
import BlockRetailHeroForm from '../components/BlockRetailHeroForm';
import BlockRetailSixUp from '../components/BlockRetailSixUp';
import BlockPhotoLeft from '../components/BlockPhotoLeft';
import BlockRetailOverview from '../components/BlockRetailOverview';
import BlockRetailTestimonial from '../components/BlockRetailTestimonial';
import BlockRetailContact from '../components/BlockRetailContact';
import NavRetail from '../components/NavRetail';
import FooterRetail from '../components/FooterRetail';
import MetaRetail from "../components/MetaRetail";
import BlockRetailThankYou from "../components/BlockRetailThankYou";

export default function Index() {
  useEffect(() => {
    const contactLinks = document.querySelectorAll(`a[href='#contact']`);
    const contact = document.querySelector(`#contact`);

    contactLinks.forEach((link) => {
      link.addEventListener('click', function (e) {
        e.preventDefault();
        contact.scrollIntoView({
          behavior: 'smooth',
        });
      });
    });

    const body = document.querySelector('body');
    body.classList.add('body--primary');
  }, []);

  return (
    <>
      <MetaRetail
        title="CloudRetail"
        description="Our commercial kitchens are designed to help you minimize cost and maximize your delivery revenue. Find one in your neighborhood."
        url="https://retail.cloudkitchens.com/thank-you"
      />
      <NavRetail />
      <BlockRetailThankYou
        button={['speak to an expert']}
        color="grey-1"
        page="Retail"
        title="Thank you for reaching out"
        copy="We appreciate your interest in CloudRetail. One of our colleagues will get in touch with you soon."
        video
        hero={bgHero}
        info={false}
        parentId="#contactTop"
      />
    </>
  );
}
